import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"
import styled from "styled-components"


const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "apocha_on_iphone_and_macbook_small" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Heading>
				      Wir geben Kassenbons einen Sinn.
            </Heading>
            <Subheading>
              Kassenzettel scannen, interpretieren und die Daten in Ihrer Business Domäne kategorisieren und nutzen. 
              <br/>
              <br/>
              Das ist apocha.
            </Subheading>
          </HeaderTextGroup>
          <Text>
            <StyledImage loading="eager" fluid={data.file.childImageSharp.fluid} alt="Notebook und Handy mit Screenshots des Haushaltsbuch mit Liste der Einnahmen und Ausgaben und einem Diagramm mit den Ausgaben je Kategorie"/>
          </Text>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding: 80px 0 0px 0;
  }
`
const Heading = styled.h1`
  ${props => props.theme.font_size.xlarge};
  color: ${props => props.theme.color.primary};
	font-weight: bold;
	line-height: 56px;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.xs}) {
    ${props => props.theme.font_size.large};
  }
`

const Subheading = styled.p`
  ${props => props.theme.font_size.large}
  color: ${props => props.theme.color.primary};
  font-weight: normal;
  line-height: 1.2em;
  margin-bottom: 12px;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.xs}) {
    ${props => props.theme.font_size.regular};
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const Text = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
	margin-top: -25px;
  width: 500px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`
