import * as React from "react"
import { graphql } from "gatsby"

import styled from "styled-components"
import Footer from "../components/sections/footer"
import Header from "../components/sections/header"
import Layout from "../components/common/layout"
import Navigation from "../components/navigation/navigation"
import Screenshots from "../components/sections/screenshots"
import Seo from "../components/common/seo"


const IndexPage = ({data}) => {
  return (
    <Layout>
      <Seo title="apocha" />
      <Navigation />
      <Header />
      <SwiperContainer>
        <Screenshots/>
      </SwiperContainer>
      <Footer />
    </Layout>
  )
}

export default IndexPage

const SwiperContainer = styled.div`
  margin: 0 auto;
  max-height: 90%;
  max-width: 90%;
  .slick-slider {
    margin: 0 auto;
    max-height: 90%;
    max-width: 90%;
  }
  
`

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`