import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Container, Section } from "../global"
import styled from "styled-components"

const Screenshots = () => {
  var settings = {
	autoplay: true,
	autoplaySpeed: 7500,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const data = useStaticQuery(graphql`
    query {
      loyaltyCampaigns: file(name: { eq: "apocha-b2b-loyalty-campaigns-magnified-with-heading-1920x1080" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      taxClasses: file(name: { eq: "apocha-b2b-tax-accounts-magified-with-heading-1920x1080" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      productCategories: file(name: { eq: "apocha-b2b-product-categories-with-heading-1920x1080" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
	<StyledSection>
		<ScreenshotContainer>
			<Slider {...settings}>
			<div>
				<StyledImage loading="eager" fluid={data.productCategories.childImageSharp.fluid}
				alt="Handy mit Screenshot der apocha Haushaltsbuch App"/>
			</div>
			<div>
				<StyledImage loading="eager" fluid={data.loyaltyCampaigns.childImageSharp.fluid}
				alt="Handy mit Screenshot der apocha Haushaltsbuch App"/>
			</div>
			<div>
				<StyledImage loading="eager" fluid={data.taxClasses.childImageSharp.fluid}
				alt="Handy mit Screenshot der apocha Haushaltsbuch App"/>
			</div>
			</Slider>
		</ScreenshotContainer>
	</StyledSection>
  );
}

export default Screenshots

const StyledSection = styled(Section)`
`

const ScreenshotContainer = styled(Container)`
`

const StyledImage = styled(Img)`
  margin-top: 0px;
`
